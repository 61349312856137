// @refresh reset

import React from "react"
import { LocalizedLink as Link } from "gatsby-theme-i18n"

import PageWrapper from "../../components/PageWrapper"
import SEO from "../../components/SEO"
import { t, Trans } from "@lingui/macro"

import imgBanner from "../../assets/image/png/service-engineering-banner.png"

import DiscoveryWorkshopSection from "../../sections/services/DiscoveryWorkshop"

const Engineering = ({ location }) => {
    return (
        <>
            <PageWrapper>
                <SEO
                    pathname={location.pathname}
                    title={t`Engineering`}
                    description={""}
                />




                <div className="tw-px-4 tw-pt-32 tw-pb-16 tw-bg-white section md:tw-pt-32">
                    <div className="tw-container tw-mx-auto lg:tw-max-w-4xl">
                        <h1 className="tw-mb-4 tw-text-6xl tw-font-bold tw-text-left tw-text-black tw-font-title sm:tw-text-center">
                            Engineering
                        </h1>
                        <p className="tw-text-base tw-italic tw-text-left sm:tw-text-center">
                            Entwicklung und Integration individueller ML-Lösungen für Ihre Produkte und Prozesse.
                        </p>
                    </div>
                </div>


                <div className="tw-px-2 tw-pb-8 tw-bg-white section">
                    <div className="tw-container tw-relative tw-mx-auto lg:tw-max-w-4xl">
                        <img src={imgBanner} className="tw-object-cover tw-object-center tw-w-full tw-aspect-video" />
                    </div>
                </div>




                <div className="tw-px-4 tw-py-8 tw-bg-white section">
                    <div className="tw-container tw-max-w-xl tw-mx-auto">
                        <p className="tw-mt-3 tw-text-base tw-text-left">
                            AMAI entwickelt intelligente Softwarelösungen mit einem hohen Anteil an Künstlicher Intelligenz. Das Prinzip „Data Science on Demand“ ermöglicht Ihnen die Entwicklung einer auf Ihre Unternehmensprozesse spezialisierte Machine-Learning-Software, ohne dafür ein eigenes Data-Science-Team aufbauen zu müssen. Diese Vorgehensweise ist besonders interessant, wenn der Nutzen den Zeitaufwand, die Kosten und Risiken von Machine-Learning-Projekten übersteigen soll. Kunden betrachten uns als eine zuverlässige externe Data Science Abteilung, die im Bedarfsfall aktiviert werden kann.
                        </p>
                    </div>
                </div>


                <div className="tw-px-4 tw-py-8 tw-bg-white section">
                    <div className="tw-container tw-max-w-xl tw-mx-auto">
                        <h4 className="tw-text-lg tw-font-medium">
                            AMAI Experten-Team
                        </h4>
                        <p className="tw-mt-3 tw-text-base tw-text-left">
                            Um End-to-End-Projekte erfolgreich durchführen zu können, sind verschiedene Rollen notwendig, die jeweils unterschiedliche Aspekte der Kommunikation, Domäne, Infrastruktur und Programmierung berücksichtigen. Das AMAI Team besteht aus Experten, die in ihren jeweiligen Fachbereichen über hohe Kompetenzen aufweisen und daher den gesamten Entwicklungsprozess direkt entlang ihrer spezifischen Bedürfnisse gestalten können.
                        </p>
                    </div>
                </div>


                <div className="tw-px-4 tw-py-8 tw-bg-white section">
                    <div className="tw-container tw-max-w-xl tw-mx-auto">
                        <h4 className="tw-text-lg tw-font-medium">
                            AMAI AI Plattform
                        </h4>
                        <p className="tw-mt-3 tw-text-base tw-text-left">
                            Anhand der Erfahrung abgeschlossener Projekte wurde von AMAI eine AI Plattform mit verschiedenen AI Building Blocks entwickelt. Mittels dem Wiedereinsatz bewährter Services, spezieller Frameworks und erprobter Code-Fragmente, ist AMAI in der Lage Projektzeiten zu verkürzen und ihren Projekterfolg zu steigern.
                        </p>

                    </div>
                </div>


                {/* Banner for Discovery Workshop */}
                {/* <DiscoveryWorkshopSection /> */}


                <div className="tw-px-4 tw-py-8 tw-bg-white section">
                    <div className="tw-container tw-max-w-xl tw-mx-auto">
                        <h4 className="tw-text-lg tw-font-medium">
                            Lizenzfreie Open Source Software
                        </h4>
                        <p className="tw-mt-3 tw-text-base tw-text-left">
                            AMAI schätzt den kreativen, wissenschaftlichen und technologischen Fortschritt und ist bestrebt fortschrittliche Ideen in die Entwicklung von intelligenter Software zu übertragen. Auch deshalb fördern wir die Idee der Open Source Software. Open Source Technologie ermöglicht ihnen das Einsparen von Kosten bei der Entwicklung, Inbetriebnahme und Instandhaltung von Softwareanwendungen.
                        </p>
                    </div>
                </div>


                <div className="tw-px-4 tw-py-8 tw-bg-white section">
                    <div className="tw-container tw-max-w-xl tw-mx-auto">
                        <h4 className="tw-text-lg tw-font-medium">
                            Bei Wunsch On-Premises
                        </h4>
                        <p className="tw-mt-3 tw-text-base tw-text-left">
                            Unternehmen, die mit sensiblen Daten und sicherheitsrelevanter Software arbeiten, haben besondere Anforderungen an ML-Systeme. AMAI verfügt über die notwendige Erfahrung, um intelligente Software On-Premises zu entwickeln, um so eine hohe Datensicherheit und Kontrolle über Inhouse Softwarelösungen zu gewährleisten.
                        </p>
                    </div>
                </div>


                <div className="tw-px-4 tw-py-8 tw-bg-white section">
                    <div className="tw-container tw-max-w-xl tw-mx-auto">
                        <h4 className="tw-text-lg tw-font-medium">
                            Prototyping
                        </h4>
                        <p className="tw-mt-3 tw-text-base tw-text-left">
                            Projektbedingungen und -Requirements ändern sich laufend und erfordern deshalb unmittelbares Anpassen von Tools und Code. AMAI pflegt eine agile und iterative Arbeitsweise, die mit Methoden unterschiedlicher Prototyping-Formen ergänzt ist, um rasch Lösungsansätze zu entwickeln und prüfen zu können.
                        </p>
                    </div>
                </div>


                <div className="tw-px-4 tw-py-8 tw-bg-white section">
                    <div className="tw-container tw-max-w-xl tw-mx-auto">
                        <h4 className="tw-text-lg tw-font-medium">
                            Code-Konventionen
                        </h4>
                        <p className="tw-mt-3 tw-text-base tw-text-left">
                            Je nach Kunde, Projektgröße und -komplexität sind im Rahmen iterativer Arbeitspraktiken das Einhalten allgemeinverständlicher Programmierparadigmen notwendig. Wir gewährleisten die Lesbarkeit, Verständlichkeit und Wartbarkeit intelligenter Software durch striktes Einhalten spezifischer Vorgaben unserer Kunden.
                        </p>
                    </div>
                </div>

            </PageWrapper>
        </>
    )
}
export default Engineering
